import { j as t } from "./jsx-runtime-B6kdoens.js";
import g from "react";
import { B as o } from "./Button-ChOUYHf0.js";
import { D as a } from "./Dialog.component-9plNKAVm.js";
import { I as r } from "./Icon-DEu72w0a.js";
import { T as k } from "./Text-DyXxCJgw.js";
import { T as n } from "./Tooltip-Dy7rqQaX.js";
import { u as C } from "./useMediaQuery-BLIs2rKV.js";
import { F as y } from "./FileIcon.component-dotRenhu.js";
import { c as D, g as s } from "./utils-CJ9afRe1.js";
import { L as F } from "./LoadingBox.component-DrW6QtEP.js";
const c = g.forwardRef((e, i) => /* @__PURE__ */ t.jsx(
  o,
  {
    ...e,
    className: D("hover:bg-white/20 focus-visible:ring-0 rounded-full w-6 h-6 aspect-square p-0", e.className),
    variant: "ghost",
    size: "sm",
    ref: i
  }
));
c.displayName = "FullScreenDocumentViewer.ActionButton";
const d = (e) => /* @__PURE__ */ t.jsx("div", { className: "flex items-center gap-4 relative z-20", children: e.children });
d.displayName = "FullScreenDocumentViewer.Actions";
const m = (e) => /* @__PURE__ */ t.jsxs(
  "div",
  {
    onClick: (i) => {
      i.preventDefault(), i.stopPropagation();
    },
    className: "flex-1 w-full flex max-w-full mx-auto items-stretch flex-col ",
    children: [
      /* @__PURE__ */ t.jsx(F, { title: "Loading", hint: "This will take a moment..", className: "left-1/2 -ml-24 top-1/2 -mt-24 absolute z-0" }),
      /* @__PURE__ */ t.jsx("div", { className: "relative z-10 flex-1 w-full h-full flex items-stretch [&>_div]:w-full", children: e.children })
    ]
  }
);
m.displayName = "FullScreenDocumentViewer.Content";
const u = (e) => /* @__PURE__ */ t.jsx(
  "div",
  {
    onClick: (i) => {
      i.preventDefault(), i.stopPropagation();
    },
    children: e.children
  }
);
u.displayName = "FullScreenDocumentViewer.Footer";
const h = (e) => /* @__PURE__ */ t.jsx("div", { className: "flex items-center gap-4 relative", children: e.children });
h.displayName = "FullScreenDocumentViewer.Next";
const x = (e) => /* @__PURE__ */ t.jsx("div", { className: "flex items-center gap-4 relative", children: e.children });
x.displayName = "FullScreenDocumentViewer.Previous";
const p = (e) => {
  const i = s(e.children, m.displayName), w = s(e.children, d.displayName), v = s(e.children, h.displayName), N = s(e.children, x.displayName), j = s(e.children, u.displayName), b = C("(min-width: 768px)");
  return g.useEffect(() => {
    const l = (f) => {
      f.key === "ArrowRight" && e.onNext && e.onNext(), f.key === "ArrowLeft" && e.onPrevious && e.onPrevious();
    };
    return window.addEventListener("keydown", l), () => {
      window.removeEventListener("keydown", l);
    };
  }, [e]), /* @__PURE__ */ t.jsx(
    a,
    {
      onOpenChange: e.onOpenChange,
      open: e.open,
      children: /* @__PURE__ */ t.jsx(
        a.Content,
        {
          overlayClassName: "z-dialog",
          onOpenAutoFocus: (l) => l.preventDefault(),
          className: "min-w-svw gap-0  sm:rounded-none w-dvh min-h-dvh sm:max-w-full lg:max-w-full rounded-none bg-black-950 z-dialog",
          children: /* @__PURE__ */ t.jsxs(n.Provider, { children: [
            e.fileName && /* @__PURE__ */ t.jsxs("div", { className: "sticky bg-blue-50 z-dialog px-2 sm:px-6 pb-4 top-0 py-4 flex items-center text-black sm:mx-10", children: [
              /* @__PURE__ */ t.jsxs("div", { className: "flex-1 overflow-hidden flex items-center gap-2", children: [
                /* @__PURE__ */ t.jsxs(n, { children: [
                  /* @__PURE__ */ t.jsx(n.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(a.Close, { asChild: !0, children: /* @__PURE__ */ t.jsx(c, { className: "shrink-0 ", children: /* @__PURE__ */ t.jsx(r, { name: b ? "ArrowLeft" : "Cancel", width: 20, height: 20, strokeWidth: 1.5 }) }) }) }),
                  /* @__PURE__ */ t.jsx(n.Content, { children: e.backToTooltip ?? "Back" })
                ] }),
                /* @__PURE__ */ t.jsx(y, { className: "shrink-0", fileName: e.fileName }),
                /* @__PURE__ */ t.jsx(k, { size: "sm", className: "text-black", truncate: !0, children: e.title })
              ] }),
              w
            ] }),
            /* @__PURE__ */ t.jsxs("div", { className: "absolute left-[8px] top-1/2  z-dialog", children: [
              N,
              e.onPrevious && /* @__PURE__ */ t.jsxs(n, { children: [
                /* @__PURE__ */ t.jsx(n.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
                  o,
                  {
                    onClick: e.onPrevious,
                    className: "hover:bg-black-500 bg-black-600 text-white rounded-full  w-7 h-7 aspect-square p-0",
                    variant: "ghost",
                    size: "sm",
                    children: /* @__PURE__ */ t.jsx(r, { name: "NavArrowLeft", width: 20, height: 20 })
                  }
                ) }),
                /* @__PURE__ */ t.jsx(
                  n.Content,
                  {
                    align: "end",
                    side: "bottom",
                    alignOffset: 0,
                    children: "Previous"
                  }
                )
              ] })
            ] }),
            /* @__PURE__ */ t.jsx(
              "div",
              {
                onClick: () => {
                  var l;
                  (l = e.onOpenChange) == null || l.call(e, !1);
                },
                className: "flex-1 flex items-stretch w-full sm:pl-10 sm:pr-10",
                children: i
              }
            ),
            j,
            /* @__PURE__ */ t.jsxs("div", { className: "absolute top-1/2 right-0 z-dialog", children: [
              v,
              e.onNext && /* @__PURE__ */ t.jsxs(n, { children: [
                /* @__PURE__ */ t.jsx(n.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
                  o,
                  {
                    onClick: e.onNext,
                    className: "hover:bg-black-500 bg-black-600 text-white absolute top-1/2 right-[8px] rounded-full w-7 h-7 aspect-square p-0 ",
                    variant: "ghost",
                    size: "sm",
                    children: /* @__PURE__ */ t.jsx(r, { name: "NavArrowRight", width: 20, height: 20 })
                  }
                ) }),
                /* @__PURE__ */ t.jsx(
                  n.Content,
                  {
                    align: "end",
                    side: "bottom",
                    alignOffset: 0,
                    children: "Next"
                  }
                )
              ] })
            ] })
          ] })
        }
      )
    }
  );
}, q = Object.assign(p, {
  Content: m,
  Actions: d,
  ActionButton: c,
  Footer: u,
  Next: h,
  Previous: x
});
export {
  q as default
};
